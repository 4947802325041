import { useMessages } from "../../i18n/useMessages";

export type InputError = {
  type: 'name' | 'inquiry' | 'email' | 'phone';
};

export const hasError = (errors: InputError[], type: InputError['type']) => {
  return errors.some((e) => e.type === type);
};

export const getError = (errors: InputError[], type: InputError['type']) => {
  const error = errors.find((e) => e.type === type);
  if (!error) {
    return undefined;
  }

  return <ErrorText type={error.type} />;
};

const ErrorText = ({ type }: { type: InputError['type'] }) => {
  const getMessage = useMessages();

  switch (type) {
    case 'name':
      return <p className="text-red-500 text-sm px-4 pt-2">{getMessage('camp67-get-in-touch.error-name')}</p>;
    case 'email':
      return <p className="text-red-500 text-sm px-4 pt-2">{getMessage('camp67-get-in-touch.error-email')}</p>;
    case 'phone':
      return (
        <p className="text-red-500 text-sm px-4 pt-2">
          {getMessage('camp67-get-in-touch.error-phone')}
        </p>
      );
    case 'inquiry':
      return <p className="text-red-500 text-sm px-4 pt-2">{getMessage('camp67-get-in-touch.error-inquiry')}</p>;
  }

  return undefined;
};
