import { proxy } from 'valtio';
import { AmplitudeEvent, analyticsState, ButtonTag } from './amplitude';

export const inquiryModal = proxy({
  isOpen: false,
});

export const onClose = () => {
  inquiryModal.isOpen = false;
};

export const onOpenInquiry = () => {
  inquiryModal.isOpen = true;
  analyticsState.amplitude?.track(AmplitudeEvent.BUTTON_CLICK, {
    buttonTag: ButtonTag.OPEN_INQUIRY,
  });
};
